import React, {Component} from 'react';
import {pdf} from "@react-pdf/renderer";
import {PdfDocument} from "./pdf";
import {saveAs} from 'file-saver';
import {connect} from "react-redux";
import axios from "axios";


const generatePdfDocument = async (fileName, documentData, done, section) => {
    pdf(<PdfDocument data={documentData} />).toBlob().then((blob) => {
        saveAs(blob, fileName);
        done(section);
    });
};

const generatePdfDocumentAndSendMail = async (fileName, documentData, mail, done, error, section) => {
    pdf(<PdfDocument data={documentData} />).toBlob().then((blob) => {
        const _data = new File([blob], fileName);
        const data = new FormData();
        data.append('upload', _data);

        axios.post((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_CONTACT'] : window.location.protocol + '//' + window.location.hostname + '/contact.php'), data, {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest',
                'User': mail
            }
        }).then(() => {
            done(section);
        }).catch(() => {
            error();
        });
    });
};


class PdfDownload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            a: false,
            b: false,
            mail: '',
            error: false,
            sent: false
        };

        this.done = this.done.bind(this);
        this.error = this.error.bind(this);
        this.sent = this.sent.bind(this);
    }

    done(dl) {
        this.setState({
            [dl]: false
        })
    }

    error() {
        this.setState({
            b: false,
            error: true
        })
    }

    sent() {
        this.setState({
            b: false,
            sent: true,
            error: false
        })
    }

    render() {

        let btn = '';
        if (this.state.sent) {
            btn = (
                <button className={'link done'}>
                    <span className={'text'}>
                        {this.props.state.language[this.props.state.lang]['Sent']}
                    </span>
                </button>
            )
        } else {
            if (this.state.b === true) {
                btn = (
                    <button className={'link theme_background'} style={{display: 'flex'}}>
                        {this.props.state.language[this.props.state.lang]['Send']}

                        <div className={'loading'}>
                            <div className="spinner">
                                <div className="bounce1"/>
                                <div className="bounce2"/>
                                <div className="bounce3"/>
                            </div>
                        </div>
                    </button>
                );
            } else {
                btn = (
                    <button className={'link theme_background'} onClick={() => {
                        if (!!this.state.mail) {
                            this.setState({
                                b: true
                            });
                        }
                    }}>
                        <span className={'text'}>
                            {this.props.state.language[this.props.state.lang]['Sending']}
                        </span>
                    </button>
                )
            }
        }

        return (
            <div className={'downloadList'}>
                {(this.props.downloadable === 'Ja') &&
                <div className={'box'}>
                    {
                        (this.state.a === true
                                ?
                                <button className={'generate theme_background'}>
                                    {this.props.state.language[this.props.state.lang]['GeneratePDF']}

                                    <span className={'loading'}>
                                            <div className="spinner">
                                                <div className="bounce1" />
                                                <div className="bounce2" />
                                                <div className="bounce3" />
                                            </div>
                                        </span>
                                </button>
                                :
                                <button className={'generate ' + (this.props.downloadable === 'Ja' ? 'downloadable theme_background' : 'disabled')} disabled={this.props.downloadable !== 'Ja'} onClick={() => {
                                    this.setState({
                                        a: true
                                    });
                                }}>
                                    <span className={'text'}>
                                        {this.props.state['Auswertung'][this.props.state.lang]['DownloadButton']}
                                    </span>
                                </button>
                        )
                    }
                </div>
                }

                <div className={'box'}>
                    <label htmlFor={''}>
                        {this.props.state['Auswertung'][this.props.state.lang]['PDFMail']}
                    </label>

                    <div className={'inner'}>
                        <input type={'mail'} name={'mail'} value={this.state.mail} placeholder={'IHRE E-MAIL-ADRESSE'} onChange={e => this.setState({mail: e.target.value})} />
                        {btn}
                    </div>

                    {this.state.error &&
                        <div className={'error'}>
                            {this.props.state.language[this.props.state.lang]['SendError']}
                        </div>
                    }
                </div>

            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.a !== this.state.a && this.state.a === true) {
            axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_ADD'] : window.location.protocol + '//' + window.location.hostname + '/add.php')).then(() => {
                generatePdfDocument('BalanceAktiv_Selbstcoaching.pdf', this.props, this.done, 'a');
            });
        }

        if (prevState.b !== this.state.b && this.state.b === true) {
            axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_ADD'] : window.location.protocol + '//' + window.location.hostname + '/add.php')).then(() => {
                generatePdfDocumentAndSendMail('BalanceAktiv_Selbstcoaching.pdf', this.props, this.state.mail, this.sent, this.error, 'b');
            });
        }
    }

}

export default connect(
    (state) => {
        return {
            state: state
        }
    },
    null
)(
    PdfDownload
);
