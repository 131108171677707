import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/form.scss';
import Cell from "./cellDiv.class";
import {connect} from "react-redux";
import {setData} from "../redux/actions";
import TopBox from "./topBox.class";
import marked from "./markdown";


const CellFunc = (props) => {

    let cells = [];
    for(let i = -5; i <= 5; i++) {
        cells.push(<Cell {...props} elm={i} key={'cellDiv_' + props.section.replace(' ', '-') + '_' + props.question + '_' + i} type={'normal'} />);
    }

    return cells;
};


class Form extends Component {
    render() {

        let headline = !!this.props.state['headlines'] ? this.props.state['headlines'][this.props.state.lang][this.props.section] : '';
        let text = !!this.props.state['subheadline'] ? this.props.state['subheadline'][this.props.state.lang][this.props.section] : '';

        return (
            <div className={'formData'}>

                <TopBox section={headline} setLanguage={this.props.setLanguage} />

                <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                <div className={'questions'}>
                    {!!this.props.data && Object.keys(this.props.data).map((e, i) => {
                        let g = this.props.data[e];

                        // console.log(g);

                        return (
                            <div key={'q_' + i} className={'question'}>
                                <div className={'top'}>
                                    <div>
                                        {g.question}
                                    </div>
                                </div>

                                <div className={'slider'}>
                                    <CellFunc change={this.props.change} section={this.props.section.replace(' ', '-')} question={i} data={this.props.state['questions'][this.props.state.lang][this.props.section][i]} area={'q'} />
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        );
    }

    componentDidMount() {
        if (this.props['infoBubble']) {
            this.props.toggleBlur({
                section: this.props.section.replace(' ', '-'),
                data: this.props['infoBubble']
            });
        }
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Form
    )
);
