import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import marked from "./markdown";

import '../assets/scss/start.scss';
import TopBox from "./topBox.class";


class Start extends Component {
    render() {
        let text = !!this.props.state.start[this.props.state.lang] ? this.props.state.start[this.props.state.lang]['Unterueberschrift'] + '<br /> ' + this.props.state['Auswertung'][this.props.state.lang]['Date'] + ': ' + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

        return (
            <div className={'startContainer'}>

                <TopBox section={!!this.props.state.start[this.props.state.lang] ? this.props.state.start[this.props.state.lang]['Ueberschrift'] : ''} setLanguage={this.props.setLanguage} />

                <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                <div className={'start'}>
                    <div className={'sections'}>
                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.start[this.props.state.lang] ? marked(this.props.state.start[this.props.state.lang]['Links']) : '')}} />

                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.start[this.props.state.lang] ? marked(this.props.state.start[this.props.state.lang]['Rechts']) : '')}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        Start
    )
);
