import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../assets/scss/topBox.scss';

class TopBox extends Component {

    render() {
        return (
            <div className={'topBox'}>

                <div className={'languageSwitch'}>
                    <div className={'lang lang_de theme_background_hover' + (this.props.state.lang === 'de' ? ' theme_background' : '')} onClick={e => this.props.setLanguage('de')}>DE</div>
                    <div className={'lang lang_en theme_background_hover' + (this.props.state.lang === 'en' ? ' theme_background' : '')} onClick={e => this.props.setLanguage('en')}>EN</div>
                </div>

                <div className={'section theme_color theme_bordercolor'}>
                    {this.props.section}
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        TopBox
    )
);
