import React, {Component} from 'react';
import {Router, withRouter} from 'react-router-dom';
import { createBrowserHistory } from "history";
import {connect} from "react-redux";
import axios from 'axios';
import Cookie from "cookie.js";

import Field from "./components/field.class";

import './assets/scss/normalize.scss';
import './assets/scss/iconfont.scss';
import './assets/scss/app.scss';
import Forms from "./components/forms.class";

import {setData} from "./redux/actions";
import {getTranslation, loggedin, logout, mergeDeep} from "./components/helper";

import {ReactComponent as Minimize} from './assets/img/minimize.svg';
import {ReactComponent as Maximize} from './assets/img/maximize.svg';
import InfoBubble from "./components/infobubble.class";
import Login from './components/login.class';


const history = createBrowserHistory();

class AppRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            view: 'form',
            route: this.props.history.location.pathname,
            infoBubble: false
        };

        this.timer = null;

        this.change = this.change.bind(this);
        this.toggleBlur = this.toggleBlur.bind(this);

        this.setLanguage = this.setLanguage.bind(this);
    }

    async change(section, question, value) {
        let timestamp = new Date().getTime();
        let changes;

        if (question === 7 || question === 8) {
            let area = (question === 7) ? 'Time' : 'Meaning';

            changes = Object.assign({}, this.props.state[area], {
                'questions': Object.assign({}, this.props.state[area]['questions'], {
                    'de': Object.assign({}, this.props.state[area]['questions']['de'], {
                        [section]: Object.assign(
                            {},
                            this.props.state[area]['questions']['de'][section],
                            Object.assign({},
                                this.props.state[area]['questions']['de'][section],
                                {
                                    'value': parseInt(value)
                                }
                            )
                        )
                    }),
                    'en': Object.assign({}, this.props.state[area]['questions']['en'], {
                        [section]: Object.assign(
                            {},
                            this.props.state[area]['questions']['en'][section],
                            Object.assign({},
                                this.props.state[area]['questions']['en'][section],
                                {
                                    'value': parseInt(value)
                                }
                            )
                        )
                    })
                })
            });
        } else {
            changes = {
                'de': Object.assign({}, this.props.state.questions['de'], {
                    [section] : Object.assign(
                        {},
                        this.props.state.questions['de'][section],
                        {
                            [question]: Object.assign({},
                                this.props.state.questions['de'][section][question],
                                {
                                    value: parseInt(value)
                                }
                            )
                        }
                    )
                }),
                'en': Object.assign({}, this.props.state.questions['en'], {
                    [section] : Object.assign(
                        {},
                        this.props.state.questions['en'][section],
                        {
                            [question]: Object.assign({},
                                this.props.state.questions['en'][section][question],
                                {
                                    value: parseInt(value)
                                }
                            )
                        }
                    )
                })
            };
        }

        if (question === 7) {
            this.props.setData({
                Time: changes,
                timestamp: timestamp
            });
        } else if (question === 8) {
            this.props.setData({
                Meaning: changes,
                timestamp: timestamp
            });
        } else {
            this.props.setData({
                questions: changes,
                timestamp: timestamp
            });
        }

    }

    toggleZoom(state) {
        this.props.setData({
            zoomed: state
        });
    }

    toggleBlur(state) {
        this.setState({
            infoBubble: state
        });
    }

    setLanguage(lang) {
        Cookie.set('language', lang);

        this.language = lang;

        this.props.setData({
            lang: lang
        })
    }

    render() {
        return (
            <Router history={history}>
                    <ul className={'switcher ' + this.state.view}>
                        <li className={this.state.view === 'form' ? 'current theme_bordercolor_before theme_color': ''} onClick={() => this.setState({view: 'form'})}>{getTranslation(this, this.props.state.lang, 'Statement')}</li>
                        <li className={this.state.view === 'game' ? 'current theme_bordercolor_before theme_color': ''} onClick={() => this.setState({view: 'game'})}>{getTranslation(this, this.props.state.lang, 'Field')}</li>
                    </ul>

                    <div className={'app' + (!loggedin() || (this.state.infoBubble && this.state.infoBubble['data']['status']) ? ' blur' : '') + ' ' + this.props.state.position + ' ' + this.state.view}>
                        <Forms change={this.change} breaktimer={() => this.breaktimer} switchBoard={this.switchBoard} position={this.state.position} toggleBlur={this.toggleBlur} setLanguage={this.setLanguage} />

                        <div className={'game'}>
                            <div className={'gameBox'}>
                                <Field change={this.change} />
                            </div>
                        </div>

                        {['/Sinn', '/Arbeit', '/Gesundheit', '/Soziale-Beziehungen'].indexOf(this.props.state.route) > -1 && (
                            this.props.state.zoomed ?
                                <Minimize onClick={() => this.toggleZoom(false)} className={'zoom theme_svg_hover'} title={'ZOOM'} />
                                :
                                <Maximize onClick={() => this.toggleZoom(true)} className={'zoom theme_svg_hover'} title={'ZOOM'} />
                        )}
                    </div>

                    {(!!this.state.infoBubble && !!this.state.infoBubble['data'] && this.state.infoBubble['data']['status']) &&
                        <InfoBubble section={this.state.infoBubble['section']} infoBubble={this.state.infoBubble['data']} close={this.close} toggleBlur={this.toggleBlur} />
                    }

                    <div className={'notSupported'}>
                        <div className={'balanceAktiveLogo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />
                        <div className={'text'}>{getTranslation(this, this.props.state.lang, 'Resolution')}</div>
                    </div>

                {!loggedin() &&
                    <Login />
                }

            </Router>
        );
    }

    getQuestions(structureData, section, _language, data) {
        let _section = section.replace(' ', '-');

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                let value = data[_language];

                value
                    .filter(e => e['Typ'] === 'Frage')
                    .forEach((e, k) => {
                        if (!structureData[_language]) {
                            structureData[_language] = {};
                        }
                        if (!structureData[_language][_section]) {
                            structureData[_language][_section] = {};
                        }

                        if (e['Typ'] === 'Zeit' || e['Typ'] === 'Bedeutung') {
                            structureData[_language][_section] = {
                                section: section,
                                coin: e['Jeton'],
                                value: 0
                            };
                        } else {
                            structureData[_language][_section][k] = {
                                section: section,
                                question: e['Text'],
                                coin: e['Jeton'],
                                value: 0
                            }
                        }
                    });
            }
        }
    }

    getTimeMeaningQuestions(structureData, section, _language, data, type) {
        let _section = section.replace(' ', '-');

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                let value = data[_language];

                value
                    .filter(e => e['Typ'] === type)
                    .forEach((e, k) => {
                        // console.log(section, e['Typ']);
                        if (!structureData[_language]) {
                            structureData[_language] = {};
                        }
                        if (!structureData[_language][_section]) {
                            structureData[_language][_section] = {};
                        }

                        structureData[_language][_section] = {
                            section: section,
                            coin: e['Jeton'],
                            value: 0
                        };
                    });
            }
        }
    }

    getInfos(structureData, section, data) {
        section = section.replace(' ', '-');

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                if (!structureData[_language]) {
                    structureData[_language] = {};
                }

                structureData[_language][section] = {
                    headline: '',
                    text: '',
                    status: false
                };
                let value = data[_language];

                value
                    .filter(e => { return e['Typ'] === 'InfoBubbleUeberschrift' || e['Typ'] === 'InfoBubbleText'})
                    .forEach(e => {
                        if (e['Typ'] === 'InfoBubbleUeberschrift' || e['Typ'] === 'InfoBubbleText') {
                            let type = (e['Typ'] === 'InfoBubbleUeberschrift') ? 'headline' : 'text';

                            structureData[_language][section][type] = e['Text'];
                            structureData[_language][section]['status'] = true;
                        }
                    });
            }
        }
    }


    parseLanguage(structureData, field, data) {
        if (!structureData[field]) {
            structureData[field] = {};
        }

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                let value = data[_language];
                value.map(e => {
                    if (!structureData[field][_language]) {
                        structureData[field][_language] = {};
                    }

                    return structureData[field][_language][e['Typ']] = e['Text'];
                });
            }
        }
    }

    parseStructuredLanguage(structureData, field, data) {
        structureData[field] = {};

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                let value = data[_language];
                value.map(e => {
                    if (!structureData[field][_language]) {
                        structureData[field][_language] = {};
                    }

                    return structureData[field][_language] = e;
                });
            }
        }
    }

    parseHeadlinesLanguage(structureData, section, type, data) {
        let _section = section.replace(' ', '-');

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                if (!structureData[_language]) {
                    structureData[_language] = {};
                }

                let value = data[_language];

                value
                    .filter(e => { return e['Typ'] === type})
                    .forEach(e => {
                        if (!structureData[_language][_section]) {
                            structureData[_language][_section] = {};
                        }

                        structureData[_language][_section] = e['Text'];
                    });
            }
        }

    }

    async getXMLData() {
        const _lang = Cookie.get('language');

        let lang = this.props.state.lang;
        if (_lang === 'de' || _lang === 'en') {
            lang = _lang;
        }

        if (['/Sinn', '/Arbeit', '/Gesundheit', '/Soziale-Beziehungen'].indexOf(this.props.location.pathname) > -1) {
            this.props.setData({
                zoomed: true
            });
        } else {
            this.props.setData({
                zoomed: false
            });
        }

        this.props.setData({
            loading: true
        });

        await axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_JSON'] : window.location.protocol + '//' + window.location.hostname) + '/json.php').then(result => {
            let data = result.data;

            let structureData = {};

            data['Struktur'].map(s => {

                if (!structureData['headlines']) {
                    structureData['headlines'] = {};
                }

                if (!structureData['Time']) {
                    structureData['Time'] = {};
                }
                if (!structureData['Time']['questions']) {
                    structureData['Time']['questions'] = {};
                }

                if (!structureData['Meaning']) {
                    structureData['Meaning'] = {};
                }
                if (!structureData['Meaning']['questions']) {
                    structureData['Meaning']['questions'] = {};
                }

                if (s['View'] === 'Fragen') {
                    if (!structureData['questions']) {
                        structureData['questions'] = {};
                    }
                    if (!structureData['infoBubble']) {
                        structureData['infoBubble'] = {};
                    }

                    if (!structureData['backgrounds']) {
                        structureData['backgrounds'] = {};
                    }

                    if (!structureData['subheadline']) {
                        structureData['subheadline'] = {};
                    }

                    if (!structureData['structure']) {
                        structureData['structure'] = new Map();
                    }

                    this.getQuestions(structureData['questions'], s['Lasche'], this.props.state.lang, data[s['Lasche']]);

                    this.getInfos(structureData['infoBubble'], s['Lasche'], data[s['Lasche']]);
                    structureData['structure'].set(s['Lasche'].replace(' ', '-'));

                    this.parseHeadlinesLanguage(structureData['subheadline'], s['Lasche'], 'Text', data[s['Lasche']]);

                    this.parseHeadlinesLanguage(structureData['backgrounds'], s['Lasche'], 'Hintergrund', data[s['Lasche']]);

                    if (data[s['Lasche']][lang].filter(e => e['Typ'] === 'Zeit').length) {
                        this.getTimeMeaningQuestions(structureData['Time']['questions'], s['Lasche'], this.props.state.lang, data[s['Lasche']], 'Zeit');
                    }

                    if (data[s['Lasche']][lang].filter(e => e['Typ'] === 'Bedeutung').length) {
                        this.getTimeMeaningQuestions(structureData['Meaning']['questions'], s['Lasche'], this.props.state.lang, data[s['Lasche']], 'Bedeutung');
                    }

                    structureData['dataBackup'] = {
                        questions: structureData['questions'],
                        infoBubble: structureData['infoBubble'],
                        Time: structureData['Time'],
                        Meaning: structureData['Meaning'],
                        backgrounds: structureData['backgrounds'],
                        headlines: structureData['headlines'],
                        subheadline: structureData['subheadline']
                    };
                } else if (s['View'] === 'Start') {
                    this.parseStructuredLanguage(structureData, 'start', data[s['Lasche']]);
                } else if (s['View'] === 'Impressum') {
                    this.parseLanguage(structureData, 'imprint', data[s['Lasche']]);
                } else if (s['View'] === 'Konfiguration') {
                    structureData['config'] = data[s['Lasche']]['de'][0];

                    let styleSheet = document.getElementById('dynamicCss');

                    if (!!styleSheet) {
                        styleSheet.innerHTML =
                            'h2 { color: ' + structureData['config']['Farbe'] + ' !important; }' +
                            '.theme_color { color: ' + structureData['config']['Farbe'] + ' !important; }' +
                            '.theme_color_hover:hover { color: ' + structureData['config']['Farbe'] + ' !important; }' +
                            '.theme_background, .theme_highlight, .theme_highlight_before:before { background-color: ' + structureData['config']['Farbe'] + ' !important; }' +
                            '.theme_background_hover:hover, .theme_highlight_before_hover:hover:before { background-color: ' + structureData['config']['Farbe'] + ' !important; color:#fff !important }' +
                            '.theme_background_hover:hover svg text { fill:#fff; }' +
                            '.theme_bordercolor, .theme_bordercolor_before:before { border-color: ' + structureData['config']['Farbe'] + ' !important; }' +
                            '.theme_borderEdgecolor_before:before { border-color: ' + structureData['config']['Farbe'] + ' transparent transparent transparent !important; }' +
                            '.theme_svg, .theme_svg path, .theme_svg_hover:hover, .theme_svg_hover:hover path, .theme_hover:hover svg, .theme_hover:hover svg path { fill: ' + structureData['config']['Farbe'] + ' !important; }' +
                            '.startContainer .start h4 { color: ' + structureData['config']['Farbe'] + ' !important; }'
                        ;
                    }
                } else {
                    if (s['View'] === 'Uebersetzungen') {
                        structureData['language'] = {};

                        for (const [key, values] of Object.entries(data[s['Lasche']])) {
                            values.map(e => {
                                if (!structureData['language'][key]) {
                                    structureData['language'][key] = {};
                                }

                                return structureData['language'][key][e['Typ']] = e['Text'];
                            });
                        }
                    } else if (s['Lasche'] === 'Zeit') {
                        this.parseLanguage(structureData, 'Time', data[s['Lasche']]);
                        this.getInfos(structureData['infoBubble'], 'Time', data[s['Lasche']]);
                    } else if (s['Lasche'] === 'Bedeutung') {
                        this.parseLanguage(structureData, 'Meaning', data[s['Lasche']]);
                        this.getInfos(structureData['infoBubble'], 'Meaning', data[s['Lasche']]);
                    } else if (s['Lasche'] === 'Auswertung' || s['Lasche'] === 'Auswertung_Offline') {
                        structureData[s['ID']] = {};

                        this.parseLanguage(structureData, s['ID'], data[s['Lasche']]);
                        this.getInfos(structureData['infoBubble'], 'Auswertung', data[s['Lasche']]);
                    } else {
                        structureData[s['ID']] = {};

                        this.parseLanguage(structureData, s['ID'], data[s['Lasche']]);
                    }
                }

                this.parseHeadlinesLanguage(structureData['headlines'], s['ID'], 'Ueberschrift', data[s['Lasche']]);

                return structureData;
            });

            structureData['loading'] = false;

            // console.log(structureData, this.props.state.lang, lang);

            this.props.setData(structureData);

            this.setTimer();

        });
    }

    async componentDidMount() {
        this.getXMLData();

        if (!Cookie.get('wereHere')) {
            axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_ADD'] : window.location.protocol + '//' + window.location.hostname + '/add.php')).then(() => {
                Cookie.set('wereHere', true);
            });
        }
    }

    breaktimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    setTimer() {

        this.breaktimer();

        if (loggedin()) {
            // this.getDatabaseData();

            this.getData();
        }

    }

    getData() {
        this.timer = setTimeout(() => {
            this.breaktimer();

            if (loggedin()) {
                // this.getDatabaseData();
            }
        }, 3000);
    }

    async getDatabaseData() {

        let questions = Object.assign({}, this.props.state.questions);
        let newQuestions = {};

        let time = Object.assign({}, this.props.state.Time);
        let newTime = {};

        let meaning = Object.assign({}, this.props.state.Meaning);
        let newMeaning = {};

        let route = Object.assign({}, this.props.state.route);
        let newRoute = '';

        let timestamp = Object.assign({}, this.props.state.timestamp);
        let newTimestamp = '';

        let users = Object.assign({}, this.props.state.users);
        let newUsers = [];

        let username = Object.assign({}, this.props.state.username);
        let newUsername = [];

        let results = Object.assign({}, this.props.state.results);
        let newResults = [];

        await axios.get((process.env['NODE_ENV'] === 'development' ? process.env['REACT_APP_URI'] : window.location.protocol + '//' + window.location.hostname + '/api.php') + '?controller=data&action=getData', {
            'headers': {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: loggedin()
            }
        }).then((result) => {
            if (!!result.data && !!result.data.timestamp && result.data.timestamp !== this.props.state.timestamp) {

                if (!!result.data && !!result.data['answers'] && result.data['answers'].length) {
                    result.data['answers'].forEach(e => {
                        if (e['question'] === 7) {
                            if (!newTime['questions']) {
                                newTime['questions'] = {};
                            }

                            if (!newTime['questions']['de']) {
                                newTime['questions']['de'] = {};
                            }
                            if (!newTime['questions']['en']) {
                                newTime['questions']['en'] = {};
                            }

                            if (!newTime['questions']['de'][e['section']]) {
                                newTime['questions']['de'][e['section']] = {
                                    value:0
                                };
                            }
                            if (!newTime['questions']['en'][e['section']]) {
                                newTime['questions']['en'][e['section']] = {
                                    value:0
                                };
                            }

                            newTime['questions']['de'][e['section']]['value'] = e['answer'];
                            newTime['questions']['en'][e['section']]['value'] = e['answer'];
                        } else if (e['question'] === 8) {
                            if (!newMeaning['questions']) {
                                newMeaning['questions'] = {};
                            }

                            if (!newMeaning['questions']['de']) {
                                newMeaning['questions']['de'] = {};
                            }
                            if (!newMeaning['questions']['en']) {
                                newMeaning['questions']['en'] = {};
                            }

                            if (!newMeaning['questions']['de'][e['section']]) {
                                newMeaning['questions']['de'][e['section']] = {
                                    value:0
                                };
                            }
                            if (!newMeaning['questions']['en'][e['section']]) {
                                newMeaning['questions']['en'][e['section']] = {
                                    value:0
                                };
                            }

                            newMeaning['questions']['de'][e['section']]['value'] = e['answer'];
                            newMeaning['questions']['en'][e['section']]['value'] = e['answer'];
                        } else {
                            if (!newQuestions['de']) {
                                newQuestions['de'] = {};
                            }
                            if (!newQuestions['en']) {
                                newQuestions['en'] = {};
                            }

                            if (!newQuestions['de'][e['section']]) {
                                newQuestions['de'][e['section']] = {};
                            }
                            if (!newQuestions['en'][e['section']]) {
                                newQuestions['en'][e['section']] = {};
                            }

                            if (!newQuestions['de'][e['section']][e['question']]) {
                                newQuestions['de'][e['section']][e['question']] = {
                                    value:0
                                };
                            }
                            if (!newQuestions['en'][e['section']][e['question']]) {
                                newQuestions['en'][e['section']][e['question']] = {
                                    value:0
                                };
                            }

                            newQuestions['de'][e['section']][e['question']]['value'] = e['answer'];
                            newQuestions['en'][e['section']][e['question']]['value'] = e['answer'];
                        }
                    });
                }

                if (!!result.data && !!result.data.route && result.data.route.length) {
                    route = result.data.route;
                }

                timestamp = result.data.timestamp;

                if (!!result && !!result.data.users && result.data.users.length) {
                    newUsers = result.data.users;
                }

                if (!!result && !!result.data['client'] && result.data['client'].length) {
                    newUsername = result.data['client'];
                }

                if (!!result && !!result.data['results']) {
                    newResults = result.data['results'];
                }

                this.props.setData({
                    questions: mergeDeep({}, questions, newQuestions),
                    Time: mergeDeep({}, time, newTime),
                    Meaning: mergeDeep({}, meaning, newMeaning),
                    route: !!newRoute ? newRoute : route,
                    timestamp: !!newTimestamp ? newTimestamp : timestamp,
                    users: !!newUsers ? newUsers : users,
                    username: !!newUsername ? newUsername : username,
                    results: !!newResults ? newResults : results,
                });
            }

            this.getData();
        })
        .catch((error) => {
            clearTimeout(this.timer);
            logout(this.props);

            console.error(error);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.state.route !== this.props.state.route) {
            history.push(this.props.state.route);

            if (['/Sinn', '/Arbeit', '/Gesundheit', '/Soziale-Beziehungen'].indexOf(this.props.state.route) > -1) {
                this.props.setData({
                    zoomed: true
                });
            } else {
                this.props.setData({
                    zoomed: false
                });
            }
        }

        if (prevProps.state.user !== this.props.state.user) {
            this.setTimer();
        }
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        AppRouter
    )
);
