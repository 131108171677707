import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {sha512} from 'js-sha512';

import {setData} from "../redux/actions";

import '../assets/scss/login.scss';

import loading from '../assets/img/loading.gif';


class Login extends Component {
    constructor(props) {
        super(props);

        fetch('./assets/data/log.txt')
            .then(r => r.text())
            .then(text => {
                this.password = text.replace(/[\n\r]+/g, '');
            });

        this.state = {
            current: 'login',
            loading: false,

            ok: false,
            error: false,

            login: {
                password: true
            }
        }

        localStorage.setItem('userLogged', JSON.stringify(true));
        this.props.setData({
            userLogged: true,
        });

        this.onSuccess = this.onSuccess.bind(this);
    }


    setTab(tab) {
        this.setState({
            current: tab,
            ok: false,
            error: false
        });
    }


    change(section, e) {
        let target = e.target;

        let properties = {...this.state[section]};
        properties[target.name] = target.value;

        this.setState({
            [section]: properties
        });
    }

    submitLogin(e) {
        if (!!e) {
            e.preventDefault();
        }

        this.setState({
            error: '',
            loading: true
        });

        if (sha512(this.state.login.password) === this.password) {
            localStorage.setItem('userLogged', JSON.stringify(true));

            this.props.setData({
                userLogged: true,
            });

            this.setState({
                loading: false
            });
        } else {
            this.setState({
                error: this.props.state.language[this.props.state.lang]['LoginIncorrect'],
                loading: false
            });
        }
    }


    onSuccess() {
        this.setState({
            copied: true
        });
    }

    render() {
        if (Object.keys(this.props.state['language']).length) {
            return (
                <div className={'overlay'}>
                    <div className={'login'}>
                        {this.state.current === 'login' &&
                        <form onSubmit={e => this.submitLogin(e)}>
                            <p>{this.props.state['language'][this.props.state.lang]['Welcome']}</p>

                            <div className={'box'}>
                                <input type={'password'} name={'password'} placeholder={this.props.state['language'][this.props.state.lang]['Password']} value={this.state.login.password} onChange={e => this.change('login', e)} />
                            </div>

                            {this.state.error &&
                            <div className={'error'}>
                                {this.state.error}
                            </div>
                            }

                            <div className={'bottomBox'}>
                                <div className={'space'} />

                                <button type={'submit'} className={'theme_background'}>
                                    {this.state.loading ?
                                        <img src={loading} alt={'Loading...'} />:
                                        this.props.state['language'][this.props.state.lang]['SignIn']
                                    }
                                </button>

                                <div className={'logo'} style={{backgroundImage: 'url(' + this.props.state.config['Logo'] + ')'}} />
                            </div>

                        </form>
                        }

                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

}


export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Login
    )
);
