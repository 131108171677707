import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/form.scss';
import Cell from "./cellDiv.class";
import TopBox from "./topBox.class";
import {connect} from "react-redux";
import {getTranslationHeadline} from "./helper";
import {setData} from "../redux/actions";
import marked from "./markdown";


const CellFunc = (props) => {

    let cells = [];
    for(let i = 0; i <= 100; i=i+5) {
        cells.push(<Cell {...props} elm={i} key={'cellDiv_' + props.section + '_' + props.question + '_' + i} type={'percentage'} />);
    }

    return cells;
};

class TimeMeaning extends Component {

    render() {

        if (!!this.props.info) {
            let data = !!this.props.data['questions'] ? this.props.data['questions'][this.props.state.lang] : null;
            let text = !!this.props.add ? this.props.add[this.props.state.lang]['Text'] : '';

            if (!!data) {
                let _total = Object.keys(data).map(e => { return data[e].value});
                let total = _total.length ? _total.reduce((a, b) => a+b) : 0;

                let headline = !!this.props.add ? this.props.add[this.props.state.lang]['Ueberschrift'] : '';

                return (
                    <div className={'formData'}>

                        <TopBox section={headline} setLanguage={this.props.setLanguage} />

                        <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                        <div className={'questions'}>
                            {!!data && Object.keys(data).map((e, i) => {
                                let g = data[e];
                                let rest = 100 - this.props.totalPercantage;

                                return (
                                    <div key={'q_' + i} className={'question'}>
                                        <div className={'top'}>
                                            {getTranslationHeadline(this.props.state.headlines, this.props.state.lang, g.section.replace(' ', '-'))} <span>({g.value} %)</span>
                                        </div>

                                        <div className={'slider slider100'}>
                                            <CellFunc change={this.props.change} section={e} question={this.props.question} data={g} rest={rest} area={'tm'} />
                                        </div>
                                    </div>
                                );
                            })}

                            <div className={'total'}>
                                <div className={'val'}>
                                    {this.props.state.language[this.props.state.lang]['Points'].replace('*total*', total)}
                                </div>
                            </div>

                        </div>

                    </div>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }

    }

    componentDidMount() {
        if (this.props.state.results.timeMeaning === false) {
            let data = Object.assign({}, this.props.state.results);
            data.timeMeaning = true;

            this.props.setData({
                results: data
            });
        }

        if (this.props['infoBubble']) {
            this.props.toggleBlur({
                section: this.jsUcfirst(this.props.section.replace(' ', '-')),
                data: this.props['infoBubble']
            });
        }
    }

    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        TimeMeaning
    )
);
