import React, {Component} from 'react';
import marked from "./markdown";

import {ReactComponent as Info} from '../assets/img/info.svg';
import {ReactComponent as Close} from '../assets/img/close.svg';
import '../assets/scss/infobubble.scss';
import {connect} from "react-redux";
import {setData} from "../redux/actions";


class InfoBubble extends Component {

    close() {
        this.props.setData({
            infoBubble: {
                'de': Object.assign({}, this.props.state.infoBubble['de'], {
                    [this.props.section]: Object.assign({}, this.props.state.infoBubble['de'][this.props.section], {
                        status: false
                    })
                }),
                'en': Object.assign({}, this.props.state.infoBubble['en'], {
                    [this.props.section]: Object.assign({}, this.props.state.infoBubble['en'][this.props.section], {
                        status: false
                    })
                })
            }
        });

        this.props.toggleBlur(false);
    }


    render() {
        return (
            <div className={'infoBubbleBox'}>
                <div className={'infoBubble theme_background theme_borderEdgecolor_before'}>
                    <div className={'infoBubbleInner'}>
                        <Close className={'close'} onClick={e => this.close()} />

                        <h3>{this.props.infoBubble['headline']}</h3>
                        <div className={'infoText'} dangerouslySetInnerHTML={{__html: (!!this.props.infoBubble['text'] ? marked(this.props.infoBubble['text']) : '')}} />

                        <Info className={'infobubble'} />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            state: state
        }
    },
    {
        setData
    }
)(
    InfoBubble
);
